@import '../node_modules/prismjs/themes/prism-tomorrow';

.centered {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  &.flex-inline {
    flex-direction: row;
    justify-content: center;
  }
}

.react-loading-skeleton {
  min-height: 38px;
}

.css-1rhbuit-multiValue {
  background-color: #2659D4 !important;
}

.flatpickr-input[readonly] {
  background-color: #fff;
}

.select-hours-next {
  >.css-26l3qy-menu {
    position: relative !important;
  }
}

.select-rows-per-page {
  >.css-26l3qy-menu {
    position: initial !important;
  }
}

.pagination {
  >li.active {
    height: fit-content !important;
  }
}

.form-control[readonly] {
  opacity: 1 !important;
}

.flex-inline {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.invoice-spacing {
  margin: 1.45rem 0;
}

label {
  font-weight: bold;
}

.fw-bold {
  font-weight: bold;
}