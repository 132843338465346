// Toasts

.toast {
  &.show {
    z-index: 1030;
  }

  .close:focus {
    outline: none;
  }
}

@include media-breakpoint-down(xs) {
  .toast {
    left: 1rem;
  }
}