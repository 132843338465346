@import '../bootstrap-extended/_include.scss';
@import '../components/_include.scss';

.dark-layout {

  // Autocomplete Navbar
  .autocomplete-container {
    .suggestions-list {
      .suggestion-item {
        &.suggestion-title-wrapper h6 {
          color: $theme-dark-text-muted-color;
        }
      }
    }
  }

  // Ecommerce Application
  .ecommerce-application .list-view .ecommerce-card .card-body {
    border-right-color: $theme-dark-border-color;
  }

  // Pagination Break
  .pagination {
    .break {
      background-color: $theme-dark-pagination-bg;

      a {
        color: $theme-dark-body-color;
      }
    }
  }

  // Invoice data table
  .invoice-list-wrapper header {
    background-color: $theme-dark-card-bg;
  }

  // BlockUI
  .block-ui-container {
    .block-ui-overlay {
      background: $theme-dark-body-bg;
    }

    .block-ui-message {
      color: $theme-dark-body-color;
    }
  }

  // Form Control
  .form-control {
    background-color: transparent;
  }

  // Apex Charts
  .apexcharts-gridlines-vertical line {
    stroke: $theme-dark-border-color;
  }

  // Horizontal Menu Scrollbar
  .horizontal-menu {
    .horizontal-menu-wrapper {
      .navbar-horizontal {
        ul.dropdown-menu {
          &::-webkit-scrollbar-thumb {
            background: $theme-dark-body-bg;
          }

          &::-webkit-scrollbar-track {
            background: $theme-dark-border-color;
          }
        }
      }
    }
  }
}