.vh-5 {
  height: 5vh;
  position: relative !important;
}

.no-top {
  top: 0 !important;
}

.size-sm {
  height: 25px;
  width: 25px;
}

.size-md {
  height: 55px;
  width: 55px;
}

.size-lg {
  height: 80px;
  width: 80px;
}